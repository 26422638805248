<template>
  <div>
    <vx-card
      title="إنشاء كوبون"
      subtitle="تفعيل كوبون للنظام"
      title-icon="SaleIcon"
      title-color="primary"
    >
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <div class="title vx-row m-2">
            <label class="w-full text-lg mt-5 mb-4">رمز الكوبون</label>
            <vs-input
              v-validate="'required|length:8'"
              class="w-1/2"
              placeholder="ABCD5835"
              name="CouponCode"
              data-vv-scope="CouponCodeSenderForm"
              data-vv-as="رمز الكوبون"
              v-model="coupon.code"
              :class="coupon.code.length > 8 ? 'text-danger' : ''"
            />
            <vs-chip
            dir="ltr"
              class="mt-2 text-xs"
              :color="coupon.code.length == 8 ? 'success' : 'danger'"
              >{{  coupon.code.length }} / 8</vs-chip
            >
            <span class="text-danger text-sm" v-show="errors.has('CouponCode')">
              {{ errors.first("CouponCode") }}
            </span>
          </div>

          <div class="desc mt-5 vx-row m-2">
            <label class="vs-input--label text-lg w-full mb-4">القيمة</label>
            <vs-input
              v-validate="'required|numeric'"
              class="w-1/2"
              name="ValueOfCoupon"
              data-vv-scope="CouponCodeSenderForm"
              data-vv-as="القيمة"
              placeholder="رقم يعبر عن القيمة"
              v-model="coupon.value"
              @change="checkValue()"
              @keypress="isNumber()"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('ValueOfCoupon')"
            >
              {{ errors.first("ValueOfCoupon") }}
            </span>

            <label
              class="vs-input--label text-lg mt-4 w-full"
              for="is_percentage"
            >
              نسبة مئوية؟

              <vs-checkbox
                id="is_percentage"
                v-model="coupon.is_percentage"
                class="inline-block"
              @change="checkValue()"
              >
              </vs-checkbox>
            </label>
          </div>
          <div class="vx-row">
            <div class="w-full m-5">
              <label class="vs-input--label text-lg w-full mb-4"
                >عدد مرات الإستخدام</label
              >

              <vs-input
                v-validate="'required|min:0|max:65'"
                class="w-1/2 mt-4"
                name="CouponUsageCount"
                data-vv-scope="CouponCodeSenderForm"
                data-vv-as="عدد مرات الإستخدام"
                placeholder="عدد مرات الإستخدام"
                v-model="coupon.usage_count"

              @keypress="isNumber()"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('CouponUsageCount')"
              >
                {{ errors.first("CouponUsageCount") }}
              </span>
            </div>
          </div>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-2 mt-10 seperator"></div>
        <div
          class="
            vx-row
            w-full
            text-center
            justify-items-center justify-center
            mt-10
          "
        >
          <vs-button
            @click="createCouponCode"
            class="text-lg mt-5 mb-5"
            dir="ltr"
            type="filled"
          >
            إنشاء كوبون
          </vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      coupon: {
        code: "",
        value: "",
        usage_count: "",
        is_percentage: false,
      },
    };
  },
  methods: {
    async createCouponCode() {
      let result = await this.$validator.validateAll("CouponCodeSenderForm");

      if (result) {
        var bodyFormData = new FormData();
        if (this.coupon.code)
          bodyFormData.set("code", this.coupon.code);
          bodyFormData.set("value", this.coupon.value);
          bodyFormData.set("usage_count", this.coupon.usage_count);
          bodyFormData.set("is_percentage", this.coupon.is_percentage ? 1 : 0);

        this.createCoupon(bodyFormData)
          .then((response) => {
          this.successDialog(response.data.message);
          this.$router.push('coupons');
            this.popupActive = false;
            this.initValues();
          })
          .catch((error) => {
            this.errorDialog(error);
          });
      }
    },
    ...mapActions("coupons", ["createCoupon"]),
    checkValue(){
      let maxValueOfCouponPercentage = 90;
      if(this.coupon.is_percentage){
        if(this.coupon.value > 100)
        {
          this.coupon.value = maxValueOfCouponPercentage;
        }
        else if(typeof this.coupon.is_percentage === 'string'){
          this.coupon.is_percentage = maxValueOfCouponPercentage;
        }
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }


  },
};
</script>

<style>
</style>
